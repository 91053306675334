@import url("https://fonts.googleapis.com/css2?family=PT+Sans&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  scroll-behavior: smooth;
  position: relative;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  color: inherit !important;
}

::selection {
  background-color: var(--primary-color--);
  color: #fff;
}



/* google translate drop down */
.VIpgJd-ZVi9od-ORHb {
  display: hidden;
}
#google_translate_element {
  position: absolute;
  left: 5px;
  z-index: 1;
}

.goog-te-banner-frame {
  display: none !important;
}

body {
  top: 0px !important;
}

.skiptranslate iframe.skiptranslate {
  display: none !important;
  visibility: hidden !important;
}
/* .goog-te-gadget#text {
      display: none;
    } */
.goog-te-gadget img {
  display: none !important;
}
.goog-te-gadget span {
  display: none !important;
}

#google_translate_element_navbar > div:last-child#text {
  display: none;
}
.goog-te-gadget {
  font-size: 0px;
}
.skiptranslate.goog-te-gadget {
  font-size: 0px !important;
}
/* Style for the select element */
.goog-te-combo {
  width: 150px !important; /* Adjust width as needed */
  padding: 4px !important;
  font-size: 16px !important;
  border: 1px solid #ccc !important;
  border-radius: 10px !important;
  background-color: #fff !important;
  color: #333 !important;
  /* height: 35px !important; */
}

/* Style for the dropdown arrow */
.goog-te-combo-arrow {
  position: absolute !important;
  top: 50% !important;
  right: 10px !important;
  transform: translateY(-50%) !important;
}

/* Style for the dropdown arrow icon */
.goog-te-combo-arrow img {
  width: 12px !important;
  height: auto !important;
}

/* Style for the options */
.goog-te-combo option {
  font-size: 14px !important;
  background-color: #fff !important;
  color: #333 !important;
}
#goog-gt-tt,
.goog-te-balloon-frame {
  display: none !important;
}
.goog-text-highlight {
  background: none !important;
  box-shadow: none !important;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display: none !important;
}

/* Target the div with ID goog-gt-tt */
#goog-gt-tt {
  /* Override border-radius */
  border-radius: 0 !important;
  /* Override margin */
  margin: 10px 0 0 -23px !important;
  /* Override padding */
  padding: 5px !important;
  /* Override font-family */
  font-family: Arial, sans-serif !important;
  /* Override width */
  width: auto !important;
  /* Override visibility */
  visibility: visible !important;
  /* Override left */
  left: 0 !important;
  /* Override top */
  top: 0 !important;
  /* Override display */
  display: none !important; /* Remove comment if you want to override display */
}

.goog-tooltip {
  display: none !important;
}
.goog-tooltip:hover {
  display: none !important;
}
.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

#goog-gt-tt,
.goog-te-balloon-frame {
  display: none !important;
}
.goog-text-highlight {
  background: none !important;
  box-shadow: none !important;
}
/* text highlatioin remove */
.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
  /* display: none !important; */
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
@media (max-width: 769px) {
  .goog-te-gadget .goog-te-combo {
    margin: 4px 0;
    width: 100px !important;
  }
}
.navbar-menu {
  position: absolute;
  top: 60px; /* Adjust as needed */
  right: 2; /* Adjust as needed */
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  z-index: 999; /* Ensure it's above other content */
}
